var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{staticClass:"align-stretch d-flex responsive-card",attrs:{"cols":"12","xs":"12","sm":"6","md":"6","xl":"3"}},[_c('v-card',{attrs:{"width":"100%","elevation":"4"}},[_c('v-card-title',{staticClass:"px-6 pb-0 h6 mb-4 d-flex justify-space-between"},[_c('div',{},[_c('span',{staticClass:"h6 font-weight-bold"},[_vm._v(_vm._s(_vm.$t('heading.instance.siteDetails.loadingSpeed.title')))])]),(_vm.$store.state.home.app.page_speed_enabled)?_c('a',{staticClass:"full-report-link font-weight-600 p-2 d-flex align-center",attrs:{"href":`https://pagespeed.web.dev/report?url=${encodeURIComponent(
          _vm.instance.url
        )}`,"target":"_blank"}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" $externallink")]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('heading.instance.siteDetails.loadingSpeed.link')))])],1):_vm._e()]),_c('v-card-text',[_c('v-row',[(_vm.isSpeedDefined || _vm.$store.state.home.app.page_speed_enabled)?[_c('v-col',{staticClass:"mt-4 pt-2 pb-0",attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center flex-column"},[_c('progress-speed-chart',{attrs:{"value":_vm.isSpeedDefined ? _vm.speedDesktop : '-',"color":_vm.speedMobile == '?'
                    ? '#E5E5F0'
                    : _vm.speedDesktop >= 90
                    ? 'success'
                    : _vm.speedDesktop >= 50
                    ? 'warning'
                    : 'error'}}),(_vm.isSpeedDefined)?_c('p',{staticClass:"p-2 text-center mt-3 mb-0"},[_vm._v(" "+_vm._s(_vm.$t('heading.instance.siteDetails.loadingSpeed.desktop'))+" ")]):_vm._e()],1)]),_c('v-col',{staticClass:"mt-4 pt-2 pb-0",attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center flex-column"},[_c('progress-speed-chart',{attrs:{"value":_vm.isSpeedDefined ? _vm.speedMobile : '-',"color":_vm.speedMobile == '?'
                    ? '#E5E5F0'
                    : _vm.speedMobile >= 90
                    ? 'success'
                    : _vm.speedMobile >= 50
                    ? 'warning'
                    : 'error'}}),(_vm.isSpeedDefined)?_c('p',{staticClass:"p-2 text-center mt-3 mb-0"},[_vm._v(" "+_vm._s(_vm.$t('heading.instance.siteDetails.loadingSpeed.mobile'))+" ")]):_vm._e()],1)]),(!_vm.isSpeedDefined)?_c('h6',{staticClass:"p-2 font-weight-light text--heading text-center mb-0 mt-4 w-100"},[_vm._v(" "+_vm._s(_vm.$t('heading.instance.siteDetails.loadingSpeed.disabled'))+" "),_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99","offset-overflow":"","nudge-bottom":"4px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('a',_vm._g(_vm._b({staticClass:"primary--text",attrs:{"href":"#"}},'a',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('button.why'))+" ")])]}}],null,false,3347391152)},[(_vm.$store.state.home.app.page_speed_enabled)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('tooltip.instance.siteDetails.security.loadingSpeed.noData.first'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('tooltip.instance.siteDetails.security.loadingSpeed.noData.first'))+" ")])])],1):_vm._e()]:[_c('v-col',{staticClass:"d-flex align-center justify-center flex-column mt-3"},[_c('loadingSpeedInfoCardIllustration'),_c('div',{staticClass:"p-4 base--text"},[_vm._v(" "+_vm._s(_vm.$t('heading.instance.siteDetails.loadingSpeed.disabled'))+" "),_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99","offset-overflow":"","nudge-bottom":"4px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('a',_vm._g(_vm._b({attrs:{"href":"#"}},'a',attrs,false),on),[_vm._v(_vm._s(_vm.$t('button.why')))])]}}])},[(_vm.$store.state.home.app.page_speed_enabled)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('tooltip.instance.siteDetails.security.loadingSpeed.noData.first'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('tooltip.instance.siteDetails.security.loadingSpeed.noData.first'))+" ")])])],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }