<template>
  <v-col cols="12" xl="6" class="align-stretch d-flex responsive-card" v-if="isReportProviderEnabled">
    <v-card
      width="100%"
      elevation="4"
      class="analytics-card analytics-card--visitors"
    >
      <template v-if="!loading">
        <v-card-text>
          <v-tabs v-model="activeTab">
            <v-tab class="py-2" v-ripple="false">
              <div class="d-flex align-center">
                <v-icon
                  size="32"
                  :color="activeTab === 0 ? 'primary' : ''"
                  class="mr-4"
                >
                  $user
                </v-icon>
                <div class="d-flex flex-column">
                  <p
                    class="
                      p-4
                      mb-0
                      text-start
                      heading--text
                      font-weight-600
                      analytics-card__title
                    "
                    :class="activeTab !== 0 ? 'gray--text text--darken-1' : ''"
                  >
                    {{ $t('heading.instance.siteDetails.analytics.visitors.unique') }}
                  </p>
                  <div class="d-flex justify-space-between">
                    <template v-if="isReportProviderEnabled">
                      <p
                        class="current-data mb-0 heading--text font-weight-600"
                      >
                        {{ visitorsData.current ? visitorsData.current : 0 }}
                      </p>
                      <delta-indicator
                        v-if="visitorsData.delta"
                        class="mt-2"
                        :value="visitorsData.delta"
                      />
                    </template>
                    <template v-else>
                      <span class="gray--text text--darken-2 font-weight-600">
                        -
                      </span>
                    </template>
                  </div>
                </div>
              </div>
            </v-tab>
            <v-tab class="py-2" v-ripple="false" :disabled="!isReportProviderEnabled">
              <div class="d-flex align-center">
                <v-icon
                  size="32"
                  :color="activeTab === 1 ? 'primary' : ''"
                  class="mr-4"
                >
                  $logout
                </v-icon>
                <div class="d-flex flex-column">
                  <p
                    class="
                      p-4
                      mb-0
                      text-start
                      heading--text
                      font-weight-600
                      analytics-card__title
                    "
                    :class="activeTab !== 1 ? 'gray--text text--darken-1' : ''"
                  >
                    {{ $t('heading.instance.siteDetails.analytics.visitors.session') }}
                  </p>
                  <div class="d-flex justify-space-between">
                    <template v-if="isReportProviderEnabled">
                      <p
                        class="current-data mb-0 heading--text font-weight-600"
                      >
                        {{
                          sessionTimeData.current ? sessionTimeData.current : 0
                        }}
                      </p>
                      <delta-indicator
                        v-if="sessionTimeData.delta"
                        class="mt-2"
                        :value="sessionTimeData.delta"
                      />
                    </template>
                    <template v-else>
                      <span class="gray--text text--darken-2 font-weight-600">
                        -
                      </span>
                    </template>
                  </div>
                </div>
              </div>
            </v-tab>
          </v-tabs>
          <template v-if="!isReportProviderEnabled">
            <div
              class="
                no-data-container
                d-flex
                align-center
                justify-center
                flex-column
                mx-auto
              "
              style="height: 220px; max-width: 500px"
            >
            <hostingDatabasesIllustration /> 

              <h5 class="p-3 gray--text text--darken-2 mb-2 mt-4">
                {{ $t('message.emptyTable.visitors.title') }}
              </h5>
              <p class="text-center p-4">
                {{ $t('message.emptyTable.visitors.description') }}
              </p>
            </div>
          </template>
          <v-tabs-items v-else v-model="activeTab" class="">
            <v-tab-item>
              <visitors-chart
                v-if="!loading"
                :style="options"
                type="unique"
                :chartdata="visitorsData"
              ></visitors-chart>
            </v-tab-item>
            <v-tab-item>
              <visitors-chart
                v-if="!loading"
                :style="options"
                type="session"
                :time="true"
                :chartdata="sessionTimeData"
              ></visitors-chart>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </template>
      <template v-else>
        <chart-card-skeleton />
      </template>
    </v-card>
  </v-col>
</template>

<script>
import VisitorsChart from "../charts/VisitorsChart.vue";
import DeltaIndicator from "../DeltaIndicator.vue";
import ChartCardSkeleton from "./ChartCardSkeleton.vue";
import hostingDatabasesIllustration from "../../components/illustrations/hosting/hosting-databases-illustration.vue";

export default {
  components: {
    VisitorsChart,
    DeltaIndicator,
    ChartCardSkeleton,
    hostingDatabasesIllustration,
  },
  data: () => ({
    activeTab: 0,
    options: {
      position: "relative",
      height: "220px",
    },
  }),
  computed: {
    isReportProviderEnabled() {
      return this.$store.state.home.app.report_provider_enabled;
    },
  },
  props: {
    loading: Boolean,
    fetching: Boolean,
    visitorsData: {
      type: Object,
      default: () => ({
        current: 0,
        delta: 0,
      }),
    },
    sessionTimeData: {
      type: Object,
      default: () => ({
        current: 0,
        delta: 0,
      }),
    },
  },
};
</script>

<style lang="scss" scoped>
.current-data {
  font-size: $font-size-xlg;
  line-height: $line-height-xlg;
}
</style>
