<template>
  <div class="chart-container">
    <ApexChart
      ref="dailyLine"
      height="250"
      type="area"
      :options="options"
      :series="series"
    ></ApexChart>
  </div>
</template>

<script>
import ApexChart from "vue-apexcharts";

export default {
  components: {
    ApexChart,
  },
  props: {
    chartdata: Object,
    type: {
      default: "Visitors",
      type: String,
    },
    time: {
      default: false,
      type: Boolean
    }
  },
  mounted() {
    if(this.isRTL) {
      this.chartdata.datasets[0] = this.chartdata.datasets[0].reverse()
      this.chartdata.labels = this.chartdata.labels.reverse()
    }
  },
  computed: {
    isRTL() {
      return this.$vuetify.rtl
    },
    series() {
      return [
        {
          name: this.$t(`heading.instance.siteDetails.analytics.visitors.${this.type}`),
          data: this.chartdata.datasets[0],
        },
      ];
    },
  },
  data() {
    return {
      options: {
        colors: ["#07C07E"],
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 0.0,
            opacityFrom: 0.4,
            opacityTo: 0.05,
            stops: [0, 100],
          },
        },

        stroke: {
          colors: ["#07C07E"],
          curve: "straight",
          width: 2,
        },
        width: "100%",
        tooltips: {
          xaxis: {},
        },
        grid: {
          borderColor: "#DEDEF1",
          strokeDashArray: 9,
          borderWidth: "1px",
        },
        xaxis: {
          axisBorder: {
            show: false,
          },
          // type: "datetime",
          categories: this.chartdata.labels,
          labels: {
            minHeight: 76,
            rotate: 0,
            hideOverlappingLabels: true,
            style: {
              colors: "#797997",
              fontSize: "12px",
              lineHeight: "16px",
              fontWeight: "700",
            },
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          labels: {
            minWidth: 24,
            formatter: (value) => {
              if(!this.time) {
                return value
              }
              //  format number to hours minutes seconds
              var sec_num = parseInt(value, 10); // don't forget the second param
              var hours = Math.floor(sec_num / 3600);
              var minutes = Math.floor((sec_num - hours * 3600) / 60);
              var seconds = sec_num - hours * 3600 - minutes * 60;

              if (hours > 0) {
                return hours + "h " + minutes + "m " + seconds + "s";
              } else if (minutes > 0) {
                return minutes + "m " + seconds + "s";
              } else {
                return seconds + "s";
              }
            },
            style: {
              colors: "#797997",
              fontSize: "12px",
              lineHeight: "16px",
              fontWeight: "700",
            },
          },
          tickAmount: 5,
          opposite: this.$vuetify.rtl
        },
        chart: {
          width: "100%",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
        },
        legend: {
          itemMargin: {
            vertical: 20,
          },
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.chart-container::v-deep {
  .apexcharts-canvas {
    margin-bottom: 12px;
  }
  .apexcharts-legend {
    margin-top: -24px;
    justify-content: space-between !important;

    .apexcharts-legend-series {
      margin-left: 4px !important;
      margin-right: 4px !important;
    }
  }
  .apexcharts-xaxis-label {
    min-width: 80px;

    tspan {
      padding: 0 2px;
    }
  }
}
</style>
