<template>
  <v-col
    cols="12"
    xs="12"
    sm="6"
    md="6"
    xl="3"
    class="align-stretch d-flex responsive-card"
  >
    <v-card width="100%" elevation="4">
      <v-card-title class="px-6 pb-0 h6 mb-4 font-weight-bold"
        >{{ $t('heading.instance.siteDetails.installation.title') }}</v-card-title
      >
      <v-card-text class="mb-4">
        <v-row no-gutters>
          <v-col cols="6" class="d-flex flex-column pr-2 pl-0 pb-0 pt-0">
            <span class="p-3 mb-1">{{ $t('general.wordpress') }}</span>
            <template v-if="wordpress.version">
              <h1
                class="version"
                :class="
                  wordpress.update_available ? 'error--text' : 'primary--text'
                "
              >
                {{ wordpress.version }}
              </h1>
              <span class="p-4 mt-1" v-if="!wordpress.update_available">
                <v-icon size="16" color="success" class="mb-1">$alertok</v-icon>
                {{ $t('message.status.updated') }}
              </span>
              <span class="p-4 mt-1 lighten-text" v-else>
                <v-icon size="16" color="error" class="mb-1"
                  >$alertwarning</v-icon
                >
                {{ $t('message.updateAvailable', {version: wordpress.update_version}) }}
              </span>
            </template>
            <template v-else>
              <span class="p-4 not-installed base--text">
                <v-icon size="16" class="mb-1 mr-2" color="gray"
                  >$alerterror</v-icon
                >
                {{ $t('message.status.install.no') }}
              </span>
              <a class="p-3 d-flex align-center mt-1">
                <v-icon size="16" color="primary" class="mr-2">
                  $installIcon
                </v-icon>

                <span class="font-weight-bold mt-1">{{ $t('button.installNow') }}</span>
              </a>
            </template>
          </v-col>
          <v-col cols="6" class="d-flex flex-column pl-2 pr-0 pb-0 pt-0">
            <span class="p-3 mb-1">{{ $t('general.woocommerce') }}</span>
            <h1
              class="version"
              :class="
                woocommerce.update_available ? 'error--text' : 'primary--text'
              "
              v-if="woocommerce.version"
            >
              {{ woocommerce.version }}
            </h1>
            <template v-if="woocommerce.version">
              <span class="p-4 mt-1" v-if="!woocommerce.update_available">
                <v-icon size="16" color="primary" class="mb-1">$alertok</v-icon>
                {{ $t('message.status.updated') }}
              </span>
              <span class="p-4 mt-1" v-else>
                <v-icon size="16" color="error" class="mb-1"
                  >$alertwarning</v-icon
                >
                {{ $t('message.updateAvailable', {version: woocommerce.update_version}) }}
              </span>
            </template>
            <template v-else>
              <span class="p-4 not-installed base--text">
                <v-icon size="16" class="mb-1 mr-2" color="gray"
                  >$alerterror</v-icon
                >
                {{ $t('message.status.install.no') }}
              </span>
              <a
                @click="$emit('action-button-install-woocommerce', instance)"
                class="p-3 d-flex align-center mt-1"
                v-if="instance.getUserPrivileges('wordpress.manage_plugins')"
              >
                <v-icon size="16" color="primary" class="mr-2">
                  $installIcon
                </v-icon>

                <span class="font-weight-bold mt-1">{{ $t('button.installNow') }}</span>
              </a>
            </template>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pt-0">
        <template
          v-if="!wordpress.update_available && !woocommerce.update_available"
        >
          <v-btn
            elevation="0"
            block
            disabled
            :color="'primary lighten-2'"
            :loading="checkingUpdates"
            :class="'primary--text text--darken-1'"
            class="font-weight-bold"
          >
            <v-icon size="16">{{ "$checkcircle" }}</v-icon>
            <span class="primary-text" v-if="woocommerce.version">{{ $t('heading.instance.siteDetails.installation.current.system') }}</span>
            <span class="primary-text" v-else>{{ $t('heading.instance.siteDetails.installation.current.wordpress') }}</span>
            <template v-slot:loader>
              <loader color="white" size="24" />
            </template>
          </v-btn>
        </template>
        <template v-else>
          <v-btn
            elevation="0"
            block
            :color="'primary'"
            :loading="checkingUpdates || instance.hasUpdateInProgress()"
            @click="
              wordpress.update_available
                ? openUpdateModal()
                : $emit('action-update-woocommerce')
            "
            :class="'white--text'"
            class="font-weight-bold"
            v-if="(wordpress.update_available || woocommerce.update_available) && (instance.getUserPrivileges('wordpress.update'))"
          >
            <v-icon size="16">{{ "$update" }}</v-icon>
            <span v-if="wordpress.update_available">{{ $t('button.wordpress.update') }}</span>
            <span v-else>{{ $t('button.woocommerce.update') }}</span>
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-col>
</template>

<script>
import { OpenUpdatesModalMixin } from "../../mixins/OpenModalAction";
import Loader from "../Loader.vue";

export default {
  mixins: [OpenUpdatesModalMixin],
  components: {
    Loader,
  },
  data() {
    return {};
  },
  computed: {
    wordpress() {
      return {
        version: this.instance.status.wordpress,
        update_available:
          this.instance.status.wordpress_available >
          this.instance.status.wordpress,
        update_version: this.instance.status.wordpress_available,
      };
    },
    woocommerce() {
      return {
        version: this.instance.status.woocommerce,
        update_available:
          this.instance.status.woocommerce_available >
          this.instance.status.woocommerce,
        update_version: this.instance.status.woocommerce_available,
      };
    },
  },
  props: {
    instance: Object,
    fetching: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.version {
  font-size: $font-size-h2;
  line-height: $line-height-h2;
}
.v-btn.v-btn--outlined {
  height: 32px;
  padding: 6px 16px;
  font-size: $font-size-sm;
  line-height: $line-height-sm;
  font-weight: $font-weight-bold;
  width: 110px;
  border-radius: 5px;
}
.not-installed {
  display: flex;
  align-items: center;
  height: 36px;
}
.v-card__title {
  font-size: $font-size-lg;
  line-height: $line-height-lg;
}
.v-card {
  &__actions::v-deep {
    .v-btn {
      min-height: map-get($btn-sizes, default) + px;
      height: unset;
      padding-top: 8px;
      padding-bottom: 8px;
      max-width: 100%;
      white-space: unset;
      .v-btn__content {
        width: 100%;
      }
    }
  }
}

.v-card__text {
  color: var(--v-text-darken2) !important;
}

.lighten-text {
  color: var(--v-gray-lighten1) !important;
} 

.primary-text {
  color: var(--v-primary-base);
}

</style>
