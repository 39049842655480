<template>
  <v-col
    cols="12"
    xs="12"
    sm="6"
    md="6"
    xl="3"
    class="align-stretch d-flex responsive-card"
  >
    <v-card width="100%" elevation="4">
      <v-card-title class="px-6 pb-0 h6 mb-4 d-flex justify-space-between">
        <div class="">
          <span class="h6 font-weight-bold">{{ $t('heading.instance.siteDetails.loadingSpeed.title') }}</span>
        </div>
        <a
          v-if="$store.state.home.app.page_speed_enabled"
          :href="`https://pagespeed.web.dev/report?url=${encodeURIComponent(
            instance.url
          )}`"
          target="_blank"
          class="full-report-link font-weight-600 p-2 d-flex align-center"
        >
          <v-icon size="16"> $externallink</v-icon>

          <span class="ml-1">{{ $t('heading.instance.siteDetails.loadingSpeed.link') }}</span>
        </a>
      </v-card-title>

      <v-card-text>
        <v-row>
          <template
            v-if="isSpeedDefined || $store.state.home.app.page_speed_enabled"
          >
            <v-col cols="6" class="mt-4 pt-2 pb-0">
              <div class="d-flex align-center flex-column">
                <progress-speed-chart
                  :value="isSpeedDefined ? speedDesktop : '-'"
                  :color="
                    speedMobile == '?'
                      ? '#E5E5F0'
                      : speedDesktop >= 90
                      ? 'success'
                      : speedDesktop >= 50
                      ? 'warning'
                      : 'error'
                  "
                ></progress-speed-chart>
                <p
                  v-if="isSpeedDefined"
                  class="p-2 text-center mt-3 mb-0"
                >
                  {{ $t('heading.instance.siteDetails.loadingSpeed.desktop') }}
                </p>
              </div>
            </v-col>
            <v-col cols="6" class="mt-4 pt-2 pb-0">
              <div class="d-flex align-center flex-column">
                <progress-speed-chart
                  :value="isSpeedDefined ? speedMobile : '-'"
                  :color="
                    speedMobile == '?'
                      ? '#E5E5F0'
                      : speedMobile >= 90
                      ? 'success'
                      : speedMobile >= 50
                      ? 'warning'
                      : 'error'
                  "
                ></progress-speed-chart>
                <p
                  v-if="isSpeedDefined"
                  class="p-2 text-center mt-3 mb-0"
                >
                  {{ $t('heading.instance.siteDetails.loadingSpeed.mobile') }}
                </p>
              </div>
            </v-col>
            <h6
              v-if="!isSpeedDefined"
              class="
                p-2
                font-weight-light
                text--heading text-center
                mb-0
                mt-4
                w-100
              "
            >
              {{ $t('heading.instance.siteDetails.loadingSpeed.disabled') }}
              <v-tooltip
                transition="custom-tooltip"
                open-delay="150"
                bottom
                z-index="99"
                offset-overflow
                nudge-bottom="4px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <a v-on="on" v-bind="attrs" class="primary--text" href="#">
                    {{ $t('button.why') }}
                  </a>
                </template>
                <span v-if="$store.state.home.app.page_speed_enabled">
                  {{ $t('tooltip.instance.siteDetails.security.loadingSpeed.noData.first')}}
                </span>
                <span v-else>
                  {{ $t('tooltip.instance.siteDetails.security.loadingSpeed.noData.first')}}
                </span>
              </v-tooltip>
            </h6>
          </template>
          <template v-else>
            <v-col class="d-flex align-center justify-center flex-column mt-3">
              <loadingSpeedInfoCardIllustration/>

              <div class="p-4 base--text">
                {{ $t('heading.instance.siteDetails.loadingSpeed.disabled') }}
                <v-tooltip
                  transition="custom-tooltip"
                  open-delay="150"
                  bottom
                  z-index="99"
                  offset-overflow
                  nudge-bottom="4px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <a href="#" v-on="on" v-bind="attrs">{{ $t('button.why') }}</a>
                  </template>

                  <span v-if="$store.state.home.app.page_speed_enabled">
                    {{ $t('tooltip.instance.siteDetails.security.loadingSpeed.noData.first')}}
                  </span>
                  <span v-else>
                    {{ $t('tooltip.instance.siteDetails.security.loadingSpeed.noData.first')}}
                  </span>
                </v-tooltip>
              </div>
            </v-col>
          </template>
        </v-row>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import ProgressSpeedChart from "../charts/ProgressSpeedChart.vue";
import loadingSpeedInfoCardIllustration from "../illustrations/loading-speed-info-card-illustration.vue";

export default {
  components: { 
    ProgressSpeedChart,
    loadingSpeedInfoCardIllustration
  },
  computed: {
    speedDesktop() {
      return this.instance.performance_score.desktop !== null
        ? this.instance.performance_score.desktop * 100
        : null;
    },
    speedMobile() {
      return this.instance.performance_score.mobile !== null
        ? this.instance.performance_score.mobile * 100
        : null;
    },
    isSpeedDefined() {
      return this.speedDesktop !== null && this.speedMobile !== null;
    },
  },
  props: {
    fetching: Boolean,
    instance: Object,
  },
};
</script>

<style lang="scss" scoped>
.full-report-link {
  color: map-get($primary, base);
  .v-icon {
    color: map-get($primary, base);
  }
  transition: color 0.24s ease;
  &:hover {
    color: map-get($primary, lighten1);
    .v-icon {
      color: map-get($primary, lighten1);
    }
  }
}
.row {
  margin: 0 -16px;
}
.v-card__title {
  line-height: $line-height-base;
}
</style>
